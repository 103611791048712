import useUtils from '@/composables/useUtils';
import store from '@/store';

const useTourUtils = () => {
  const { formatDecimalNumber } = useUtils();

  const getTourFees = (tour) => {
    if (!tour.tourType) {
      return {
        accountCommission: 0.0,
        juraAccCommission: 0.0,
        taxiCommission: 0.0,
        tourTotalFee: 0.0,
      };
    }

    const { driverRate, passengerNum, rate } = tour.tourType;
    const { pricePerEnvironmentTicket } =
      store.getters['setting/globalSettings'];

    const taxiCommission = driverRate + tour.tourAdjustmentFee;
    const juraAccCommission = formatDecimalNumber(
      pricePerEnvironmentTicket * passengerNum + tour.juraAdjustmentFee
    );
    const accountCommission = formatDecimalNumber(
      rate -
        driverRate -
        pricePerEnvironmentTicket * passengerNum +
        tour.accountAdjustmentFee
    );

    const tourTotalFee = taxiCommission + juraAccCommission + accountCommission;

    return {
      accountCommission,
      juraAccCommission,
      taxiCommission,
      tourTotalFee,
    };
  };

  const storeTour = (tour) => {
    store.commit('tour/storeTour', {
      ...store.getters['tour/tour'],
      ...tour,
    });
  };

  const getTourStatusVariant = (tourStatus) => {
    if (tourStatus.isPaid) return 'success';
    if (tourStatus.isEdited) return 'secondary';
    if (tourStatus.isCancelled) return 'danger';
  };

  return {
    // Method
    getTourStatusVariant,
    getTourFees,
    storeTour,
  };
};

export default useTourUtils;
