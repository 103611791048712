<template>
  <b-modal
    ref="refModal"
    id="frontier-tour-attachments-modal"
    title="Frontier Tour Attach Files"
    ok-title="Upload"
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    @ok="validateForm"
  >
    <validation-observer ref="tourAttachmentsForm">
      <b-form id="add-frontier-tour-attachments">
        <validation-provider
          #default="{ errors }"
          name="Upload Files"
          rules="required"
        >
          <b-form-group
            label="Upload Files"
            label-for="frontier-tour-attachments"
          >
            <b-form-file
              id="frontier-tour-attachments"
              name="frontier-tour-attachments"
              :state="errors.length > 0 ? false : null"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              multiple
              v-model="tourAttachments"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormFile } from 'bootstrap-vue';
import { computed, ref } from "@vue/composition-api";
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import useTourAttachment from '@/modules/frontier-tour/composables/useTourAttachment';
import vSelect from 'vue-select';
import store from '@/store';

export default {
  setup() {
    const { getTourAttachments, uploadTourAttachments } = useTourAttachment();

    const tourAttachmentsForm = ref(null);
    const refModal = ref(null);
    const tourAttachments = ref([]);
    const tourId = computed(() => store.getters['tour/tour'].id);

    const validateForm = (modalEvt) => {
      modalEvt.preventDefault();

      tourAttachmentsForm.value.validate().then(async (valid) => {
        if (!valid) return;

        const response = await uploadTourAttachments(tourId.value, tourAttachments.value);
        if (response !== null) await getTourAttachments(tourId.value);
        refModal.value.hide();
      });
    };

    return {
      // Reactive
      tourAttachmentsForm,
      tourAttachments,
      refModal,

      // Method
      validateForm,

      // Validator
      required
    };
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormFile,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-frontier-tour-attachments {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>