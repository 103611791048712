<template>
  <div>
    <b-alert
      variant="danger"
      :show="tour === undefined"
    >
      <h4 class="alert-heading">
        Error fetching tour data
      </h4>
      <div class="alert-body">
        No tour found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'frontier-tour-list' }"
        >
          Tour List
        </b-link>
        for other tours.
      </div>
    </b-alert>

    <b-alert
      variant="warning"
      :show="((tour && tour.id !== null) && tour.tourStatus.isEdited)"
    >
      <h4 class="alert-heading">
        This tour has been edited
      </h4>
      <div class="alert-body">
        This is just a preview of the original tour. Check the
        <span
          class="font-weight-bolder"
          v-if="tour"
        >
          Tour #{{ tour.voucherNum }}
        </span>
        to see the new changes.
      </div>
    </b-alert>

    <template>
      <b-row
        v-if="tour"
        class="invoice-preview"
      >
        <b-col :lg="(!tour.tourStatus.isEdited && !tour.tourStatus.isCancelled) ? 8 : 12">
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <b-card-body class="invoice-padding pb-0">
              <div class="d-flex flex-md-row flex-column justify-content-between align-items-md-center mb-1">
                <b-col class="text-right text-md-left">
                  <div class="d-flex d-row">
                    <b-badge
                      id="tour-status"
                      :variant="`${getTourStatusVariant(tour.tourStatus)}`"
                      class="mb-1 ml-50"
                    >
                      {{ tour.tourStatus.name }}
                    </b-badge>
                    <b-tooltip
                      target="tour-status"
                      placement="top"
                    >
                      <p class="mb-0">
                        Tour Status
                      </p>
                    </b-tooltip>
                  </div>

                  <h4>
                    Voucher
                    <span class="font-weight-bolder">#{{ tour.voucherNum }}</span>
                  </h4>
                </b-col>
              </div>

              <div class="d-flex flex-md-row flex-column justify-content-between align-items-md-center">
                <b-col
                  md="6"
                  order="2"
                  order-md="1"
                  class="tour-wrap mt-md-0 mt-1"
                >
                  <div class="d-flex align-items-center justify-content-md-between mb-75">
                    <h6 class="tour-label mb-25">Tour Type:</h6>
                    <p class="pl-25 mb-25">{{ tour.tourType.name }}</p>
                  </div>

                  <div class="d-flex align-items-center justify-content-md-between mb-75">
                    <h6 class="tour-label mb-25">Passengers:</h6>
                    <p class="pl-25 mb-25">{{ tour.tourType.passengerNum }}</p>
                  </div>
                </b-col>

                <b-col
                  md="6"
                  order="1"
                  order-md="2"
                  class="tour-wrap mt-md-0 mt-1"
                >
                  <div class="d-flex align-items-center justify-content-md-between mb-75">
                    <h6 class="tour-label mb-25">Date Added:</h6>
                    <p class="pl-25 mb-25">{{ dateFormated(tour.dateAddedUtc) }}</p>
                  </div>

                  <div class="d-flex align-items-center justify-content-md-between mb-75">
                    <h6 class="tour-label mb-25">Tour Date:</h6>
                    <p class="pl-25 mb-25">{{ dateFormated(tour.tourDateUtc) }}</p>
                  </div>
                </b-col>
              </div>

              <div class="d-flex flex-md-row flex-column justify-content-between align-items-md-center">
                <b-col
                  md="6"
                  class="tour-wrap mt-md-0 mt-1"
                >
                  <div class="d-flex align-items-center justify-content-md-between mb-75">
                    <h6 class="tour-label mb-25">Taxi Driver:</h6>
                    <p class="pl-25 mb-25">{{ `${tour.taxi.firstName} ${tour.taxi.lastName}` }}</p>
                  </div>
                </b-col>
                <b-col
                  md="6"
                  class="tour-wrap"
                >
                  <div class="d-flex align-items-center justify-content-md-between mb-75">
                    <h6 class="tour-label mb-25">Taxi Vehicle:</h6>
                    <p v-if="tour.vehicle" class="pl-25 mb-25">{{ `${tour.vehicle.regNum} - ${tour.vehicle.model}` }}</p>
                  </div>
                </b-col>
              </div>
            </b-card-body>

            <b-card-body class="invoice-padding">
              <div class="d-flex flex-md-row flex-column justify-content-md-end align-items-md-center">
                <b-col md="4">
                  <b-card-text>
                    <h6>Notes</h6>
                    <p>{{ tour.notes }}</p>
                  </b-card-text>
                </b-col>

                <b-col
                  md="8"
                  class="mt-md-6 d-flex justify-content-end"
                >
                  <div class="invoice-total-wrapper">

                    <div class="invoice-total-item">
                      <p class="invoice-total-title text-truncate">Taxi Rate:</p>
                      <p class="invoice-total-amount text-truncate">{{ formatCurrency(tour.taxiCommission) }}</p>
                    </div>

                    <div class="invoice-total-item">
                      <p class="invoice-total-title text-truncate">Frontier Acc:</p>
                      <p class="invoice-total-amount text-truncate">{{ formatCurrency(tour.accountCommission) }}</p>
                    </div>

                    <div class="invoice-total-item">
                      <p class="invoice-total-title text-truncate">JURA Account:</p>
                      <p class="invoice-total-amount text-truncate">{{ formatCurrency(tour.juraAccCommission) }}</p>
                    </div>

                    <div
                      v-if="tour.tourAdjustmentFee"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title text-muted text-truncate">Taxi Adj.</p>
                      <p class="invoice-total-amount text-muted text-truncate">{{ formatCurrency(tour.tourAdjustmentFee) }}</p>
                    </div>

                    <div
                      v-if="tour.accountAdjustmentFee"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title text-muted text-truncate">Frontier Adj.</p>
                      <p class="invoice-total-amount text-muted text-truncate">{{ formatCurrency(tour.accountAdjustmentFee) }}
                      </p>
                    </div>

                    <div
                      v-if="tour.juraAdjustmentFee"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title text-muted text-truncate">JURA Adj.</p>
                      <p class="invoice-total-amount text-muted text-truncate">{{ formatCurrency(tour.juraAdjustmentFee) }}</p>
                    </div>

                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title font-weight-bolder text-truncate">Total</p>
                      <p class="invoice-total-amount text-truncate">{{ formatCurrency(tour.tourTotalFee) }}</p>
                    </div>
                  </div>
                </b-col>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col :lg="(!tour.tourStatus.isEdited && !tour.tourStatus.isCancelled) ? 4 : 12">
          <b-col
            class="px-0 py-0"
            v-if="!tour.tourStatus.isEdited && !tour.tourStatus.isCancelled"
          >
            <b-card>
              <b-button
                variant="outline-primary"
                class="mb-75"
                block
                :to="{ name: 'frontier-tour-edit', params: { id: $route.params.id } }"
              >
                Edit
              </b-button>

              <b-button
                variant="outline-primary"
                class="mb-75"
                block
                @click="printTour"
              >
                Print
              </b-button>

              <b-button
                variant="outline-secondary"
                class="mb-75"
                block
                @click="openTourAttachmentFormModal"
              >
                Attach files
              </b-button>

              <b-button
                variant="danger"
                class="mb-75"
                block
                @click="cancel"
              >
                Cancel
              </b-button>
            </b-card>
          </b-col>

          <b-col class="px-0 py-0">
            <tour-attach-list />
          </b-col>
        </b-col>
      </b-row>
      <tour-attach-modal ref="tourAttachmentFormRef" />
    </template>
  </div>
</template>

<script>
import { BAlert, BBadge, BButton, BCard, BCardBody, BCardText, BCol, BLink, BRow, BTableLite, VBToggle, BTooltip } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import router from '@/router';
import Swal from 'sweetalert2';
import TourAttachList from '@/modules/frontier-tour/components/TourAttachList.vue';
import TourAttachModal from '@/modules/frontier-tour/components/TourAttachModal.vue';
import useTour from '@/modules/frontier-tour/composables/useTour';
import useTourAttachment from '@/modules/frontier-tour/composables/useTourAttachment';
import useTourUtils from '@/modules/frontier-tour/composables/useTourUtils';
import useUtils from '@/composables/useUtils';

export default {
  setup() {
    const { getTour, downloadTour, cancelTour } = useTour();
    const { getTourAttachments } = useTourAttachment();
    const { formatDateDdMmYyyyHi, convertUtcToLocalDate, formatCurrency } = useUtils();
    const { getTourStatusVariant } = useTourUtils();

    const tour = ref(null);
    const tourAttachmentFormRef = ref(null);

    const dateFormated = (date) => formatDateDdMmYyyyHi(convertUtcToLocalDate(date));
    const openTourAttachmentFormModal = () => { tourAttachmentFormRef.value.$refs.refModal.show(); };

    const initData = async () => {
      await getTourAttachments(router.currentRoute.params.id);
      const data = await getTour(router.currentRoute.params.id);
      tour.value = data;
    }

    const printTour = async () => {
      const response = await downloadTour(tour.value.id)

      if (response !== null) {
        // create file link in browser's memory
        const url = URL.createObjectURL(response.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    }

    const cancel = async () => {
      const { value } = await Swal.fire({
        title: 'Cancellation Confirmation',
        text: 'This action will change the status of all linked transactions to "Cancelled".',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, continue!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      await cancelTour(tour.value.id);
      const data = await getTour(router.currentRoute.params.id);
      tour.value = data;
    };

    initData();

    return {
      // Reactive
      tour,
      tourAttachmentFormRef,

      //Method
      getTourStatusVariant,
      dateFormated,
      formatCurrency,
      openTourAttachmentFormModal,
      printTour,
      cancel
    };
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BCol,
    BLink,
    BRow,
    BTableLite,
    TourAttachList,
    TourAttachModal,
    VBToggle,
    BTooltip
  }
};
</script>

<style lang="scss" scoped>
.tour-wrap {
  max-width: 300px;
}

.tour-label {
  max-width: 8rem;
}

@import "~@core/scss/base/pages/app-invoice.scss";
</style>