import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useTour = () => {
  const toast = useToast();

  const createTour = async (tour) => {
    try {
      return await store.dispatch('tour/create', tour);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const createMultipleTour = async (tour) => {
    try {
      return await store.dispatch('tour/createMultiple', tour);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const getTours = async (filter) => {
    try {
      return await store.dispatch('tour/getTours', filter);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const getTour = async (id) => {
    try {
      return await store.dispatch('tour/getTour', id);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Tour not found',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const updateTour = async (tourId) => {
    try {
      return await store.dispatch('tour/update', tourId);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const cancelTour = async (tourId) => {
    try {
      await store.dispatch('tour/cancelTour', tourId);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const resetVouchersNum = async () => {
    try {
      await store.dispatch('tour/resetVouchersNum');
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const downloadTour = async (tourId) => {
    try {
      return await store.dispatch('tour/downloadTour', tourId);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  return {
    // Method
    createTour,
    createMultipleTour,
    getTour,
    getTours,
    updateTour,
    resetVouchersNum,
    downloadTour,
    cancelTour,
  };
};

export default useTour;
