<template>
  <b-card
    no-body
    class="mb-4"
  >
    <b-card-header>
      <h6>Frontier Tour Attachments</h6>
    </b-card-header>

    <b-list-group flush>
      <b-list-group-item
        v-for="attachment in tourAttachments"
        :key="attachment.id"
      >
        <div class="d-flex justify-content-between align-items-center">
          <span>{{ attachment.fileName }}</span>
          <b-dropdown
            variant="link"
            no-caret
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="downloadAttachment(attachment)">
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Download</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteAttachment(attachment.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import { BAlert, BBadge, BButton, BCard, BCardBody, BCardHeader, BCardText, BCol, BDropdown, BDropdownItem, BLink, BListGroup, BListGroupItem, BRow, BTableLite, VBToggle } from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import Swal from 'sweetalert2';
import useTourAttachment from '@/modules/frontier-tour/composables/useTourAttachment';

export default {
  setup() {
    const { downloadTourAttachment, deleteTourAttachment, getTourAttachments } = useTourAttachment();
    const tourAttachments = computed(() => store.getters['tourAttachment/tourAttachments']);

    const downloadAttachment = async (attachment) => {
      const { data } = await downloadTourAttachment(attachment.id);

      // create file link in browser's memory
      const url = URL.createObjectURL(data);

      // create "a" HTLM element with href to file & click
      const link = document.createElement('a');
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }

    const deleteAttachment = async (id) => {
      const { value } = await Swal.fire({
        title: 'Delete file',
        text: 'Do you still want to continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, continue!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      await deleteTourAttachment(id);
      await getTourAttachments(router.currentRoute.params.id)
    }

    return {
      //Reactive
      tourAttachments,

      //Method
      downloadAttachment,
      deleteAttachment,
    };
  },
  components: {
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCol,
    BDropdown,
    BDropdownItem,
    BLink,
    BListGroup,
    BListGroupItem,
    BRow,
    BTableLite,
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>