import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useTourAttachment = () => {
  const toast = useToast();

  const getTourAttachments = async (id) => {
    try {
      return await store.dispatch('tourAttachment/getTourAttachmentList', id);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Tour not found',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const uploadTourAttachments = async (tourId, tourAttachments) => {
    try {
      return await store.dispatch('tourAttachment/uploadFiles', {
        tourId,
        tourAttachments,
      });
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const deleteTourAttachment = async (id) => {
    try {
      await store.dispatch('tourAttachment/deleteFile', id);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const downloadTourAttachment = async (id) => {
    try {
      return await store.dispatch('tourAttachment/downloadFile', id);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    // Method
    deleteTourAttachment,
    downloadTourAttachment,
    getTourAttachments,
    uploadTourAttachments,
  };
};

export default useTourAttachment;
